



















































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import {imageUrl, showTagRE} from '@/utils/common'
import {ExportExcel} from "@/utils/exportFile";

@Component({})
export default class AUser extends Vue {
  baseUrl = imageUrl
  showTagRE = showTagRE

  config = {
    type: {
      'zhike': '直客',
      'broker': '经纪人'
    },
    status: {
      1: '上架',
      2: '下架'
    }
  }
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };

  // 下载图片
  // 加载图片
  async loadImage (params: any) {
    // 图片src 必传
    let src = params.src
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.src = src
      img.crossOrigin = 'anonymous'
      img.onload = () => {
        resolve(img)
      }
      img.onerror = reject
    })
  }

// 图片转base64
  img2Base64 (image: any) {
    // 图片画到canvas
    let canvas: any = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    return canvas.toDataURL('image/png', 1.0)
  }
// base64转blob
  base64ToBlob (base64Code: any) {
    let parts = base64Code.split(';base64,')
    let contentType = parts[0].split(':')[1]
    let raw = window.atob(parts[1])
    let rawLength = raw.length
    let uInt8Array = new Uint8Array(rawLength)
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], {
      type: contentType
    })
  }

  downloadFile(href: any, filename: any) {
    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    link.setAttribute('type', 'application/octet-stream');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async downloadImg1(imageUrl: any, filename:any) {
    const image = await this.loadImage({src: imageUrl})
    let base64 = this.img2Base64(image)
    let blob = this.base64ToBlob(base64)
    // 下载图片
    this.downloadFile(URL.createObjectURL(blob), filename + '.png');

  }

  handleClose() {
    this.detailDialog = false
  }
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post('J27123', this.search)
      .then(res => {
        this.total = res.total;
        this.tableData = res.list;
      }).finally(() =>{
      this.loading = false;
    })
  }

  // 编辑
  toEdit(row: any) {
    this.$router.push({
      name: 'activityEdit',
      query:{
        activityNumber: row.activityNumber
      }
    })
  }

  // 查看
  detailDialog = false
  detailInfo: any = {}
  toDetail(row: any) {
    this.getDetail(row.activityNumber)

  }
  getDetail(activityNumber: any) {
    api
      .post('J27126', {activityNumber: activityNumber})
      .then(res => {
          this.detailInfo = res
        this.detailDialog = true
      })
  }

  setStatus(row: any, val: any) {
    // 1 上线  2 下线 3 删除
    let tips: any = val == 1 ? "是否对该项执行下线操作?" : (val == 2 ? '是否发布上线?' : ( val == 3 ? '是否删除?' : null ) )
    let apiVal: any = val == 1 ? "J27128" : (val == 2 ? 'J27127' : ( val == 3 ? 'J27129' : null ) )
    this.$confirm( tips , "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      api
              .post(apiVal, {activityNumber: row.activityNumber})
              .then((res: any) => {
                this.$message.success("操作成功");
                this.searchTable()
              })

    });
  }

  // 添加活动
  add() {
    this.$router.push({
      name: 'activityAdd'
    })
  }

  activated() {
    this.searchTable();
  }
}
